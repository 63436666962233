var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    class: _vm.studentList.length > 0 ? 'min-h-screen' : 'heightContent'
  }, [_vm._m(0), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "grid grid-cols-6 justify-start gap-2 mb-4"
  }, [_c('div', [_c('Dropdown', {
    attrs: {
      "label": "Program",
      "options": _vm.programOptions,
      "optionLabel": "name",
      "default": _vm.selectedProgram,
      "placeholder": "Select Program"
    },
    on: {
      "change": function change($event) {
        return _vm.resetClass();
      }
    },
    model: {
      value: _vm.selectedProgram,
      callback: function callback($$v) {
        _vm.selectedProgram = $$v;
      },
      expression: "selectedProgram"
    }
  })], 1), _c('div', {
    staticClass: "relative"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Class")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "disabled": !_vm.selectedProgram,
      "borderEnabled": "",
      "withIcon": "",
      "placeholder": "Search Class..."
    },
    on: {
      "input": function input($event) {
        return _vm.searchClass();
      }
    },
    model: {
      value: _vm.classKeyword,
      callback: function callback($$v) {
        _vm.classKeyword = $$v;
      },
      expression: "classKeyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1), _vm.isSearching ? _c('div', {
    staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
  }, [_vm.list_kelas.length > 0 && !_vm.isFetching ? _vm._l(_vm.list_kelas, function (item_kelas) {
    return _c('div', {
      key: item_kelas.id,
      staticClass: "py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
      on: {
        "click": function click($event) {
          return _vm.selectClass(item_kelas);
        }
      }
    }, [_vm._v(" " + _vm._s(item_kelas.nama) + " ")]);
  }) : _vm._e(), _vm.list_kelas.length === 0 && !_vm.isFetching ? [_c('p', {
    staticClass: "py-1 px-3 text-white font-normal text-center"
  }, [_vm._v("Class is not found.")])] : _vm._e()], 2) : _vm._e()]), _c('div', [_c('Dropdown', {
    attrs: {
      "label": "Status",
      "options": _vm.statusOptions,
      "optionLabel": "name",
      "default": _vm.selectedStatus,
      "placeholder": "Select Status"
    },
    model: {
      value: _vm.selectedStatus,
      callback: function callback($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  })], 1), _c('div', [_c('TextField', {
    attrs: {
      "type": "date",
      "label": "Start Date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1), _c('div', [_c('TextField', {
    attrs: {
      "type": "date",
      "label": "End Date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-2 h-full mt-5 ml-2"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Filter",
      "type": "secondary",
      "additionalClass": "bg-yellow h-11 mr-2"
    },
    on: {
      "action": _vm.filterOrSearch
    }
  }), _c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDefaultFilter,
      expression: "isDefaultFilter"
    }],
    attrs: {
      "buttonText": "Clear",
      "type": "tertiary",
      "additionalClass": " h-11"
    },
    on: {
      "action": _vm.clearFilter
    }
  })], 1)]), _c('div', {
    staticClass: "flex items-center mb-7"
  }, [_c('div', {
    staticClass: "w-1/4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search Student")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.filterOrSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Name, email, or phone number"
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "#58595B"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "flex items-center h-full ml-3 mt-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "additionalClass": "bg-yellow h-11 mr-2"
    },
    on: {
      "action": _vm.filterOrSearch
    }
  }), _c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.keyword,
      expression: "keyword"
    }],
    attrs: {
      "buttonText": "Clear",
      "type": "tertiary",
      "additionalClass": " h-11"
    },
    on: {
      "action": _vm.clearSearch
    }
  })], 1)]), _vm.studentList.length > 0 ? _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.size,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
        setCurrentPage: _vm.pageTable
      },
      "totalRows": _vm.totalRows,
      "rows": _vm.studentList,
      "columns": _vm.tableHeader,
      "sort-options": {
        enabled: false
      },
      "styleClass": "vgt-table bordered my-table"
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onSizeChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'action' ? _c('div', {
          staticClass: "flex flex-col gap-2 whitespace-nowrap"
        }, [_c('div', {
          staticClass: "flex justify-between items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "primary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              position: '170%',
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toDetail(props.row.status, props.row.studentId, props.row.studentAdministrationId);
            }
          }
        })], 1)]) : _c('div', [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])];
      }
    }], null, false, 3611963793)
  }) : _c('div', {
    staticClass: "border rounded-lg h-64 flex justify-center items-center"
  }, [_c('div', {
    staticClass: "bg-pink"
  }, [_c('p', {
    staticClass: "text-center text-neutral-400"
  }, [_vm._v("No data available")]), _c('p', {
    staticClass: "text-center text-yellow font-bold cursor-pointer",
    on: {
      "click": _vm.clearAll
    }
  }, [_vm._v("Clear All")])])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Student Administration")])]);
}]

export { render, staticRenderFns }