<template>
  <div>
    <div v-if="isLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else :class="studentList.length > 0 ? 'min-h-screen' : 'heightContent'">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Student Administration</h1>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="grid grid-cols-6 justify-start gap-2 mb-4">
          <div>
            <Dropdown label="Program" :options="programOptions" optionLabel="name" v-model="selectedProgram" @change="resetClass()" :default="selectedProgram" placeholder="Select Program" />
          </div>
          <div class="relative">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class</label>
            <div class="relative">
              <TextField :disabled="!selectedProgram" borderEnabled withIcon v-model="classKeyword" @input="searchClass()" placeholder="Search Class..." />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search />
              </div>
            </div>
            <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearching">
              <template v-if="list_kelas.length > 0 && !isFetching">
                <div
                  @click="selectClass(item_kelas)"
                  class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                  v-for="item_kelas in list_kelas"
                  :key="item_kelas.id"
                >
                  {{ item_kelas.nama }}
                </div>
              </template>
              <template v-if="list_kelas.length === 0 && !isFetching">
                <p class="py-1 px-3 text-white font-normal text-center">Class is not found.</p>
              </template>
            </div>
          </div>
          <div>
            <Dropdown label="Status" :options="statusOptions" optionLabel="name" v-model="selectedStatus" :default="selectedStatus" placeholder="Select Status" />
          </div>
          <div>
            <TextField type="date" label="Start Date" v-model="startDate" borderEnabled placeholder="Select Date" />
          </div>
          <div>
            <TextField type="date" label="End Date" v-model="endDate" borderEnabled placeholder="Select Date" />
          </div>
          <div class="grid grid-cols-2 h-full mt-5 ml-2">
            <Button buttonText="Filter" type="secondary" @action="filterOrSearch" additionalClass="bg-yellow h-11 mr-2" />
            <Button v-show="isDefaultFilter" buttonText="Clear" type="tertiary" @action="clearFilter" additionalClass=" h-11" />
          </div>
        </div>
        <div class="flex items-center mb-7">
          <div class="w-1/4">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search Student</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="filterOrSearch" v-model="keyword" withIcon borderEnabled placeholder="Name, email, or phone number" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search color="#58595B" />
              </div>
            </div>
          </div>
          <div class="flex items-center h-full ml-3 mt-4">
            <Button buttonText="Search" @action="filterOrSearch" additionalClass="bg-yellow h-11 mr-2" />
            <Button v-show="keyword" buttonText="Clear" type="tertiary" @action="clearSearch" additionalClass=" h-11" />
          </div>
        </div>
        <vue-good-table
          v-if="studentList.length > 0"
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: size,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
            setCurrentPage: pageTable
          }"
          :totalRows="totalRows"
          :rows="studentList"
          :columns="tableHeader"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table bordered my-table"
          @on-page-change="onPageChange"
          @on-per-page-change="onSizeChange"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'action'" class="flex flex-col gap-2 whitespace-nowrap">
              <div class="flex justify-between items-center">
                <Button
                  class="mr-2"
                  type="primary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                  :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                  @action="toDetail(props.row.status, props.row.studentId, props.row.studentAdministrationId)"
                />
              </div>
            </div>
            <div v-else>
              {{ props.row[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
        <div v-else class="border rounded-lg h-64 flex justify-center items-center">
          <div class="bg-pink">
            <p class="text-center text-neutral-400">No data available</p>
            <p class="text-center text-yellow font-bold cursor-pointer" @click="clearAll">Clear All</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
  name: 'Student',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search')
  },
  data: function () {
    return {
      isFetching: false,
      isSearching: false,
      isLoading: false,
      sizeChange: false,
      selectedProgram: null,
      selectedClassId: null,
      selectedClass: null,
      selectedStatus: null,
      startDate: null,
      endDate: null,
      keyword: null,
      debounce: null,
      classKeyword: '',
      list_kelas: [],
      statusOptions: [
        { id: 'OPENED', name: 'Open' },
        { id: 'COMPLETED', name: 'Completed' },
        { id: 'CANCELLED', name: 'Cancel' }
      ],
      programOptions: [],
      studentList: [],
      size: 10,
      currentPage: 0,
      totalRows: 0
    }
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    },
    tableHeader() {
      return [
        { field: 'name', label: 'Name' },
        { field: 'email', label: 'Email' },
        { field: 'program', label: 'Program' },
        { field: 'class', label: 'Class' },
        { field: 'status', label: 'Status' },
        { field: 'lastUpdate', label: 'Last Update' },
        { field: 'action', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    pageTable() {
      return this.currentPage + 1
    },
    isDefaultFilter() {
      return this.selectedProgram || this.selectedClassId || this.selectedStatus || this.startDate || this.endDate ? true : false
    }
  },
  mounted() {
    this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
      this.programOptions = []
      this.programOptions.push({
        code: 'scholarship',
        id: 'scholarship',
        name: 'Scholarship'
      })
      response.data.data.forEach((element) => {
        this.programOptions.push(element)
      })
    })
    this.isLoading = true
    this.getData()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('student', ['GET_CRM_STUDENT_ADMINISTRATION']),
    filterOrSearch() {
      this.currentPage = 0
      this.getData()
    },
    clearFilter() {
      this.resetClass()
      this.selectedProgram = this.selectedStatus = this.startDate = this.endDate = null
      this.currentPage = 0
      this.getData()
    },
    clearSearch() {
      this.keyword = null
      this.currentPage = 0
      this.getData()
    },
    clearAll() {
      this.resetClass()
      this.selectedProgram = this.selectedStatus = this.startDate = this.endDate = this.keyword = null
      this.currentPage = 0
      this.getData()
    },
    toDetail(status, studentId, studentAdministrationId) {
      this.$router.push({
        name: 'CRM Student Class Detail',
        params: {
          studentId,
          statusId: status === 'Completed' ? '2' : status === 'Open' ? '1' : '0',
          id: studentAdministrationId
        }
      })
    },
    onPageChange(params) {
      this.currentPage = params.currentPage - 1
      this.getData()
    },
    onSizeChange(params) {
      if (!this.sizeChange) {
        this.sizeChange = true
        this.size = params.currentPerPage
        this.currentPage = 0
        this.getData()
      }
    },
    getData() {
      this.showLoading()
      this.GET_CRM_STUDENT_ADMINISTRATION({
        customerId: this.customerId,
        params: {
          page: this.currentPage,
          size: this.size,
          programCode: this.selectedProgram ? this.selectedProgram.code : '',
          coreClassId: this.selectedClassId ? this.selectedClassId : '',
          status: this.selectedStatus ? this.selectedStatus.id : '',
          lastUpdatedStart: this.startDate ? `${moment(this.startDate).format('YYYY-MM-DD')}T00:00:00.000` : '',
          lastUpdatedEnd: this.endDate ? `${moment(this.endDate).format('YYYY-MM-DD')}T23:59:59.999` : '',
          q: this.keyword ? this.keyword : ''
        }
      })
        .then((res) => {
          this.totalRows = res.data.data.totalRows
          this.size = res.data.data.size
          this.currentPage = res.data.data.currentPage
          this.studentList = []
          res.data.data.data.forEach((student) => {
            this.studentList.push({
              studentAdministrationId: student.id,
              studentId: student.studentId,
              name: student.studentName,
              email: student.studentEmail,
              program: student.programName,
              class: student.className,
              status: this.toUserFriendly(student.status),
              lastUpdate: moment(student.updatedDate).format('DD MMM YYYY HH:mm')
            })
          })
          setTimeout(() => {
            this.isLoading = this.sizeChange = false
            this.hideLoading()
          }, 1000)
        })
        .catch(() => {
          this.isLoading = false
          this.hideLoading()
        })
    },
    toUserFriendly(value) {
      let forReturn = '-'
      if (value === 'REGISTERING') {
        forReturn = 'Open'
      } else {
        this.statusOptions.forEach((item) => {
          if (item.id === value) forReturn = item.name
        })
      }
      return forReturn
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') {
            self.getListKelas()
          }
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    getListKelas() {
      this.isFetching = true
      let paramsTemp = queryString.stringify({
        ...{
          limit: 300,
          sort: 'name',
          program_code: this.selectedProgram.code,
          name: this.classKeyword
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          customerId: this.customerId,
          params: paramsTemp
        })
        .then(() => {
          if (this.selectedProgram.code === 'scholarship') {
            this.list_kelas = [
              { id: 'IndoDigi', nama: 'IndoDigi' },
              { id: 'TechChamp', nama: 'Tech Champ' }
            ]
          } else {
            this.list_kelas = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
          }
          this.isSearching = true
          this.isFetching = false
        })
        .catch(function () {})
    },
    resetClass() {
      this.selectedClassId = this.classKeyword = this.selectedClass = null
    },
    selectClass(item) {
      this.selectedClassId = item.id
      this.classKeyword = item.nama
      this.selectedClass = item.nama
      this.isSearching = false
    }
  }
}
</script>
<style scoped lang="scss">
.heightContent {
  height: calc(100vh - 148px);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
